<template>
  <h1>Добро пожаловать</h1>
  <div>Это тренировочный сайт для отработки навыков Vue и Composition API</div>
  <h2>Содержание</h2>
  <h4>Getting Started | Приступая к работе</h4>
  <div style="display: flex; justify-content: center;" class="basic-card">
    <ol style="width: max-content">
      <li>
        <router-link to="/introduction">Introduction | Введение</router-link>
      </li>
      <li>
        <a href="/quick-start">Quick Start | Быстрый старт</a>
      </li>
    </ol>
  </div>
  <h4>Essentials | Существенный</h4>
  <div style="display: flex; justify-content: center;" class="basic-card">
    <ol style="width: max-content">
      <li>
        <a href="#">Creating a Vue Application | Создание приложения Vue</a>
      </li>
      <li>
        <a href="#">Template Syntax | Синтаксис шаблона</a>
      </li>
      <li>
        <a href="#">Reactivity Fundamentals | Основы реактивности</a>
      </li>

      <li>
        <a href="#">Computed Properties | Вычисляемые свойства</a>
      </li>
      <li>
        <a href="#">Class and Style Bindings | Привязки к классу и стилям</a>
      </li>
      <li>
        <a href="#">Conditional Rendering | Условный рендеринг</a>
      </li>
      <li>
        <a href="#">List Rendering | Отображение списка</a>
      </li>
      <li>
        <a href="#">Event Handling | Обработка событий</a>
      </li>
      <li>
        <a href="#">Form Input Bindings | Привязки для ввода в форму</a>
      </li>
      <li>
        <a href="#">Lifecycle Hooks | Зацепки жизненного цикла</a>
      </li>
      <li>
        <a href="#">Watchers | Наблюдатели</a>
      </li>
      <li>
        <a href="#">Template Refs | Ссылки на шаблоны</a>
      </li>
      <li>
        <a href="#">Components Basics | Основы компонентов</a>
      </li>
    </ol>

  </div>
  <h4>Components In-Depth | Подробное описание компонентов</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Component Registration | Регистрация компонентов</a>
      </li>
      <li>
        <a href="#">Props | Опора</a>
      </li>
      <li>
        <a href="#">Component Events | События компонентов</a>
      </li>
      <li>
        <a href="#">Component v-model | Компонент v-model</a>
      </li>
      <li>
        <a href="#">Fallthrough Attributes | Дополнительные атрибуты</a>
      </li>
      <li>
        <a href="#">Slots | Слоты</a>
      </li>
      <li>
        <a href="#">Provide / Inject | Обеспечивать / вводить</a>
      </li>
      <li>
        <a href="#">Async Components | Асинхронные компоненты</a>
      </li>
    </ol>
  </div>
  <h4>Reusability | Возможность повторного использования</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Composables | Составные материалы</a>
      </li>
      <li>
        <a href="#">Custom Directives | Пользовательские директивы</a>
      </li>
      <li>
        <a href="#">Plugins | Подключаемые модули</a>
      </li>
    </ol>
  </div>
  <h4>Built-in Components | Встроенные компоненты</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Transition | Переход</a>
      </li>
      <li>
        <a href="#">TransitionGroup | Переходная группа</a>
      </li>
      <li>
        <a href="#">KeepAlive | Оставайтесь в живых?</a>
      </li>
      <li>
        <a href="#">Teleport | Телепорт</a>
      </li>
      <li>
        <a href="#">Suspense | неизвестность, беспокойство, тревога ожидания</a>
      </li>
    </ol>
  </div>
  <h4>Scaling Up | Расширение масштабов</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Single-File Components | Однофайловые компоненты</a>
      </li>
      <li>
        <a href="#">Tooling | Инструментарий</a>
      </li>
      <li>
        <a href="#">Routing | Маршрутизация</a>
      </li>
      <li>
        <a href="#">State Management | Государственное управление :D</a>
      </li>
      <li>
        <a href="#">Testing | Тестирование</a>
      </li>
      <li>
        <a href="#">Server-Side Rendering (SSR) | Рендеринг на стороне сервера (SSR)</a>
      </li>
    </ol>
  </div>
  <h4>Best Practices | Лучшие практики</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Production Deployment | Развертывание производства</a>
      </li>
      <li>
        <a href="#">Performance | Производительность</a>
      </li>
      <li>
        <a href="#">Accessibility | Доступность</a>
      </li>
      <li>
        <a href="#">Security | Безопасность</a>
      </li>
      <li>
        <a href="#">Testing | Тестирование</a>
      </li>
      <li>
        <a href="#">Server-Side Rendering (SSR) | Рендеринг на стороне сервера (SSR)</a>
      </li>
    </ol>
  </div>
  <h4>TypeScript | Typescript</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Using Vue with TypeScript | Использование Vue с TypeScript</a>
      </li>
      <li>
        <a href="#">TypeScript with Composition API | Машинописный текст с композиционным API</a>
      </li>
      <li>
        <a href="#">TypeScript with Options API | TypeScript с опциями API</a>
      </li>
    </ol>
  </div>
  <h4>Extra Topics | Дополнительные темы</h4>
  <div style="display: flex; justify-content: center;">
    <ol style="width: max-content">
      <li>
        <a href="#">Ways of Using Vue | Способы использования Vue</a>
      </li>
      <li>
        <a href="#">Composition API FAQ | Часто задаваемые вопросы по составу API</a>
      </li>
      <li>
        <a href="#">Reactivity in Depth | Реактивность в глубине</a>
      </li>
      <li>
        <a href="#">Rendering Mechanism | Механизм рендеринга</a>
      </li>
      <li>
        <a href="#">Render Functions & JSX | Функции рендеринга и JSX</a>
      </li>
      <li>
        <a href="#">Vue and Web Components | Vue и веб-компоненты</a>
      </li>
      <li>
        <a href="#">Animation Techniques | Методы анимации</a>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "StartPage",
  setup() {
    return {}
  }
}

</script>

<style scoped>
li {
  text-align: left;
}
</style>/
import {createApp, reactive} from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from "vue-router";
import "./main.scss";
import StartPage from "./pages/StartPage.vue";
import IntroductionComponent from "./pages/IntroductionComponent.vue";
import QuickStartComponent from "./pages/QuickStartComponent.vue";


export const app_store = reactive({
    test: 'test'
})
window.app_store = app_store

const routerPaths = [
    {
        path: '/introduction',
        component: IntroductionComponent
    },
    {
        path:'/quick-start',
        component: QuickStartComponent
    },
    {
        path: '/',
        component: StartPage
    }
]

if (window.navigator.userAgent === 'Bibinet IOS App' || window.navigator.userAgent.includes('Mobile')) {
    document.querySelector("html").id = "is_mobile"
} else {
    document.querySelector("html").id = "is_desktop"
}

const router = createRouter({
    history: createWebHistory(),
    routes: routerPaths
})

createApp(App).use(router).mount('#app')

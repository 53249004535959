<template>
  <div style="position: absolute;top:5px; left: 5px" v-if="currentRouteName!=='/'">
    <router-link to="/">Домой</router-link>
  </div>
  <router-view></router-view>
</template>

<script>
import {computed} from "vue";
import {useRouter} from "vue-router";

export default {
  name: 'App',
  setup() {
    const router = useRouter();
    const currentRouteName = computed(() => {
      return router.currentRoute.value.path;
    });
    return {
      currentRouteName
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<template>
<h1>QuickStartComponent is work!</h1>
</template>

<script>
export default {
  name: "QuickStartComponent",
  setup() {
    return {}
  }
}
</script>